import { EventEmitter, Injectable } from '@angular/core';
import {HttpBaseService} from './http-base.service';
import {StorageService} from './storage.service';
import { Router } from '@angular/router';
import { removeSessionUser, setSessionUser } from '../../store/user/user.action';
import { removeReviewer, setReviewer } from '../../store/reviewer/reviewer.action';
import { Store } from '@ngrx/store';
import { FirebaseMessagingService } from './firebase-messaging.service';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  eventEmitter: EventEmitter<any> = new EventEmitter();
  constructor(
    private http: HttpBaseService,
    private storageService: StorageService,
    private firebaseMessagingService: FirebaseMessagingService,
    private store: Store,
    private router: Router
  ) { }

  public login(payload:any) {
    return this.http.post('auth/login', payload);
  }

  public refreshToken() {
    const payload = {
      refreshToken: this.storageService.getCookieValueByKey('refreshToken'),
      expiryFor: 'web',
    }
    return this.http.post('auth/refreshToken', payload);
  }

  public getSessionUser() {
    return this.http.get('my');
  }

  public removeAllExcept(keysToKeep: string[]): void{
    const itemsToKeep: { [key: string]: string } = {};
    
    keysToKeep.forEach(key => {
      const value = localStorage.getItem(key);
      if (value !== null) {
        itemsToKeep[key] = value;
      }
    });
  
    localStorage.clear();
  
    Object.keys(itemsToKeep).forEach(key => {
      localStorage.setItem(key, itemsToKeep[key]);
    });
  };
  

  public logout():void{
    this.firebaseMessagingService.logoutFromFirebase();
    this.storageService.removeAllCookies()
    this.store.dispatch(removeReviewer());
    this.store.dispatch(removeSessionUser());
    this.removeAllExcept(['isSideNavOpen']);
    window.location.href = `${environment.clientHost}/login`;
  }

  autoLogin(token: string, state: string, isMobile: boolean, firmId: string) {
    this.storageService.setUserToken(JSON.parse(decodeURIComponent(token)));
    this.http.get(`my`).subscribe({
      next: (reviewer: any) => {
        if (!reviewer.message) {
          this.store.dispatch(setReviewer(reviewer));
          this.store.dispatch(setSessionUser(reviewer));
          const singleTenant = reviewer.tenants.length ? reviewer.tenants[0] : '';
          const currentTenant  = reviewer.tenants.find(
            (tenant:any) => tenant.firm._id === firmId
          );
          this.storageService.setTenant(currentTenant || singleTenant);
          
            if (isMobile) {
              // handle mobile-specific logic if needed
            }
            if (state === 'my-profile' && isMobile) {
              this.router.navigateByUrl('/reviewer/profile/mobile');
            }
          
        }
      },
      error: (error) => {
        this.logout() // get user role logic
        // Emit event for logout
      }
    });
  }

  public changePassword (oldPass: string, newPass: string, confirmPass: string) {
    let refreshObject = {
      refreshToken: this.storageService.getCookieValueByKey('refreshToken'),
      expiryFor: 'web',
    };
    let pass: any = {} ;
    pass.oldPassword = oldPass;
    pass.newPassword = newPass;
    pass.confirmPassword = confirmPass;
    pass.refreshObject = refreshObject;
    return this.http.put('auth/changePassword', pass);
  }

  forgotPassword(email: {email: string}) {
    return this.http.put('auth/forgotPassword', email);
  }

  resetPassword(password: any){
      return this.http.put('auth/resetPassword', password);
  }

  getUserByHash (hash:string) {
    return this.http.get(`auth/getUserByRecoveryHash/${hash}`);
  };

  updateUser(user: any) {
    return this.http.put('auth/user/update', user);
  };

  sendAdminVerificationPhoneSms(adminId: string, mobileNo: string) {
    const body = {
      _id: adminId,
      ...(mobileNo ? { mobile: mobileNo } : {})
    }
    return this.http.put('auth/sendVerificationPhoneSms', body);
  }
  
  verifyPhone(body: any ) {
    return this.http.put('auth/verifyPhone', body);
  }

  isAuthenticated (){
    return !!(this.storageService.getToken());
  }

}
